import { useState, useEffect } from "react";
import { Device } from "@capacitor/device";
// types
import type { Days, Day } from "../types/misc";

// TODO: remove WeekInfo and LocaleWithWeekInfo type if default supported
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
interface WeekInfo {
	firstDay: Day;
	weekend: Readonly<Days>;
	minimalDays: Day;
}
export interface LocaleWithWeekInfo extends Intl.Locale {
	getWeekInfo: () => WeekInfo;
	/** @deprecated Only use as fallback for `getWeekInfo()` */
	weekInfo?: WeekInfo;
}

const DEFAULT_WEEKINFO = {
	firstDay: 1,
	weekend: [6, 7],
	minimalDays: 4,
} as const satisfies WeekInfo;

const getLocale = (locales: Intl.LocalesArgument = undefined) => {
	const locale = new Intl.Locale(new Intl.NumberFormat(locales).resolvedOptions().locale) as LocaleWithWeekInfo;
	locale.getWeekInfo ??= () => (DEFAULT_WEEKINFO); // eslint-disable-line @typescript-eslint/no-unnecessary-condition
	return locale;
};

/**
 * Do NOT destruct `locale` if you use `.weekInfo` or `.getWeekInfo()`. Do: `const locale = useLocale();`
 */
const useLocale = (useNavigatorLanguages = false): LocaleWithWeekInfo => {
	const [locale, setLocale] = useState(getLocale(useNavigatorLanguages ? navigator.languages : undefined));

	useEffect(() => {
		const handleLanguageChange = async () => (
			setLocale(getLocale(useNavigatorLanguages ? (await Device.getLanguageTag()).value : undefined))
		);

		window.addEventListener("languagechange", handleLanguageChange);

		return () => {
			window.removeEventListener("languagechange", handleLanguageChange);
		};
	}, [useNavigatorLanguages]);

	return locale;
};

export default useLocale;
